import React,{useRef, useEffect, useState} from 'react';
import { Image } from 'primereact/image';
import { Document, Page, pdfjs} from 'react-pdf';
import { Dialog } from 'primereact/dialog';

const AttachmentLine = ({ attachment}) => {
    console.log(' attachmentON LAOD = ', attachment);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [visible, setVisible] = useState(false);

    const attachImageRef = useRef(null);
    const pdfUrl = attachment.filetype==='application/pdf' ? attachment.url : null;
    useEffect(() => {pdfjs.GlobalWorkerOptions.workerSrc =`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;  }, []);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const openImagePreview = ()=>{
        if(attachment.filetype==='application/pdf')setVisible(true);
        else attachImageRef.current.show();
    }
    return (
        <React.Fragment>
        <tr  style={{ width: '100%', marginLeft: '1.1em' }}>
            <td data-label="Open" style={{ width: '10%', padding: '0' }}>
                <button style={{ margin: '0', padding: '.5em', width:"95%" }}   onClick={(e) => openImagePreview()}     className="mbsc-ios mbsc-btn-primary mbsc-btn"     > {attachment.Name} </button>

            </td>
          
        </tr>
        <Image  src={attachment.url} im ref={attachImageRef} alt="Image" zoomSrc={attachment.url} height="100"  style={{display:"none",margin:0, width:100}}  preview/>
            {pdfUrl && (
                        <Dialog dismissableMask={true} header="Attachment" headerStyle={{padding:"0", paddingLeft:"2em", paddingTop:".75em"}}  visible={visible} style={{ width: '90vw' }} onHide={() => {if (!visible) return; setVisible(false); }}>
                            <Document onLoadSuccess={onDocumentLoadSuccess} refstyle={{width:"100% !important"}} file={pdfUrl}>
                                <Page pageNumber={pageNumber} />
            </Document>
            </Dialog>)}
        </React.Fragment>
    );
};

export default AttachmentLine;